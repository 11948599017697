<template>
  <Page>
    <ViewTitle :title="Title"/>
    <p class="text-center" v-html="Description"></p>
    <div class="resetpassword">
      <b-form @submit.prevent="submit">
    
      <label class="vgs-ac-label" for="fm_currentpassword">{{ resetPasswordLabels.OldPasswordPlaceholder }}</label>   
          <b-form-input id="fm_currentpassword" 
          :aria-label="resetPasswordLabels.OldPasswordPlaceholder" 
          :type="this.passwordType" 
          v-model="form.currentpassword"    
          :placeholder="resetPasswordLabels.OldPasswordPlaceholder + ' *'" 
          @blur="$v.form.currentpassword.$touch()"
          :class="{ 'is-invalid': $v.form.currentpassword.$error }"
          class="mb-3 rounded vgs-form-input"
          ></b-form-input>
           <div v-if="$v.form.currentpassword.$error" class="invalid-feedback error-class" >
               <span v-if="!$v.form.currentpassword.required" class="float-left mb-2 mt-n2">{{ resetPasswordLabels.OldPasswordRequired }}</span>
            </div>

    
    <div class="mb-2 text-left vgs-font-small">
      {{ labels.PasswordComplexityInfo }}
    </div>
    <label class="vgs-ac-label" for="fm_password">{{ resetPasswordLabels.PasswordPlaceholder }}</label>   
        <div class="position-relative">
            <b-form-input id="fm_password" 
            :aria-label="resetPasswordLabels.PasswordPlaceholder" 
            maxlength="20"
            :type="this.passwordType" 
            v-model="form.password" 
            v-on:keyup="set_password_complexity_level_handler" 
            :placeholder="resetPasswordLabels.PasswordPlaceholder + ' *'" 
            @blur="$v.form.password.$touch()"
            :class="{ 'is-invalid': $v.form.password.$error }"
            class="mb-3 rounded vgs-form-input">
            </b-form-input>
             <div v-if="$v.form.password.$error" class="invalid-feedback error-class" >
               <span v-if="!$v.form.password.required" class="float-left mb-2 mt-n2">{{ resetPasswordLabels.PasswordRequired }}</span>
            </div>
            <div  class="vgs-pw-eye" @click="fm_password_switch()"><IconSet icon="eye" state=""></IconSet></div>
        </div>
        <div :class="!$v.form.password.$error && $v.form.password.required ? 'mb-5' : ''">
            <PasswordComplexity 
            v-if="!$v.form.password.$error && $v.form.password.required"
            :level="this.password_complexity_level">
            </PasswordComplexity>
        </div>
      
     
        <label class="vgs-ac-label" for="fm_passwordconfirm">{{ resetPasswordLabels.ConfirmPasswordPlaceholder }}</label>   
           <b-form-input id="fm_passwordconfirm" 
           :aria-label="resetPasswordLabels.ConfirmPasswordPlaceholder" 
           v-bind:type="this.passwordType" 
           maxlength="20"
          @blur="$v.form.passwordconfirmation.$touch()"
          v-model="form.passwordconfirmation"
           :placeholder="resetPasswordLabels.ConfirmPasswordPlaceholder + ' *'" 
           class="mb-3 rounded vgs-form-input"
           :class="{ 'is-invalid':  $v.form.passwordconfirmation.$error }"
           >
           </b-form-input>
           <div v-if="$v.form.passwordconfirmation.$error" class="invalid-feedback error-class" >
              <span v-if="!$v.form.passwordconfirmation.required" class="float-left mb-2 mt-n2">{{ resetPasswordLabels.ConfirmPasswordRequired }}</span>
              <span v-if="!$v.form.passwordconfirmation.sameAsPassword && $v.form.passwordconfirmation.required" class="float-left mb-2 mt-n2">{{resetPasswordLabels.ConfirmPasswordNotMatched}}</span>
          </div>
    <div class="vgs-error-messages" v-html="get_error_messages"></div>
    <b-button
      type="submit"
      variant="primary"
      class="w-100 p-3 rounded vgs-form-button"
      :disabled="isdisabled"
      >{{resetPasswordLabels.ChangePasswordButtonText}}</b-button                                         
    >
  </b-form>
    </div>
  </Page>
</template>
<script>
// import Vue from "vue";
import PasswordComplexity from "@/components/PasswordComplexity.vue";
import { required , sameAs  } from "vuelidate/lib/validators";
import { customLabels } from "@/mixins/customLabels";
import Page from '@/structure/Page.vue'
import IconSet from '@/structure/IconSet.vue'
import { vcm } from '@/mixins/vcm'
import { vvm } from '@/mixins/vvm'
import ViewTitle from '@/components/ViewTitle.vue'
import { getPageContent } from '@/services/pageContentService';
import { mapActions } from 'vuex';

export default {
  mixins: [vcm,vvm, customLabels],
  name: 'ResetPassword',
  components: {
    Page, 
    IconSet, 
    PasswordComplexity, 
    ViewTitle 
  },
  data() {
    return {
      isSuccess: false,
      isdisabled: false,
      Title: this.get_scope_translation('Passwort andren','label'),
      Description: this.get_scope_translation('Bitte geben Sie Ihr neues. Passwort ein und bestatitgen Sie dieses.','label'),
      labels: {
        PasswordComplexityInfo:this.get_scope_translation("Mind. 8 Zeichen, Groß- & Kleinbuchstaben, Zahlen", "label")
      },
      form: {
         password: '',
         passwordconfirmation:'',
         currentpassword:'',
      }
    };
  },
  async created() {
    let pageContent = await getPageContent('reset-password');
    if(pageContent.responseCode === '200') {
      this.Title  = pageContent.response.Title;
      this.Description  = pageContent.response.Description;
    }
  },
  validations: {
    form: {
      password: {
            required
      },
      currentpassword: {
            required
      },
      passwordconfirmation:{
        required ,
        sameAsPassword: sameAs('password'),
        },
    }
  },
   methods: {
      ...mapActions({
        resetPassword : 'setting/resetPasswordAction'
      }),
      submit() {
        this.$v.$touch();
        if ( this.$v.form.$invalid || this.password_complexity_level < 4) {
          return;
        }
        this.isdisabled = true;
        this.resetPassword({
          OldPassword: this.form.currentpassword,
          NewPassword: this.form.password
        }).then((result) => {
          if(result.responseCode === '200' && result.responseMessage === 'Incorrect Input') {
              this.add_error_message(this.resetPasswordLabels.IncorrectOldPassword, this.isSuccess);
              this.isdisabled = false;
          }
          if(result.responseCode === '200' && result.responseMessage === 'Ok') {
              this.isSuccess = true;
              this.add_error_message(this.resetPasswordLabels.SuccessPasswordReset, this.isSuccess);
              this.isdisabled = false;
          }
          this.isdisabled = false;
        })
      }
    },
}
</script>

<style scoped>
.resetpassword{
  margin: 50px auto 0;
  max-width: 71%;
}

.error-class {
  display: flex;
}

.was-validated .form-control:invalid, .form-control.is-invalid{
  background-image: none !important;
}
</style>