<template>
<div class="vcs-complexity" v-bind:class="current_level_item('allowed.class')">
    <div v-for="item in get_visible_password_complexity_levels" :key="item.level" class="com-section">    
        <div class="csn-item" v-bind:class="check_level(item)" v-bind:title="get_scope_translation(item.name,'label')"></div>
    </div>
    <div class="com-label">
        <div class="cml-set">{{ get_scope_translation(current_level_item('name'),'label') }}</div>
    </div>
</div>
</template>

<script>
import { vcm } from '@/mixins/vcm'

/* EMBED: password_complexity_level > mixin vcm.js
<PasswordComplexity v-bind:level="this.password_complexity_level"></PasswordComplexity>
*/

export default {
    mixins: [vcm],
    name: 'PasswordComplexity',
    props: {    
        level: Number
    },
    data() {
        return {                           
        };
    },
    computed: {  
        get_visible_password_complexity_levels: function(){
            return this.$root.config.credentials.password_complexity_levels.filter(function (obj) {
                return obj.level != 0
            })
        } 
    },
    methods: {
        check_level(item) {
            var rv = ''
            if (item.level <= this.level) {
                rv += ' active '
            }
            rv += ' allowed_' + item.allowed
            return rv
        },
        current_level_item(mode) {
            var rv = ''
            var pThis = this
            var itemSet = this.$root.config.credentials.password_complexity_levels.filter(function (obj) {
                return obj.level == pThis.level
            })
            if (itemSet.length > 0) {
                switch (mode) {
                    case 'name': 
                        rv = itemSet[0].name 
                        break
                    case 'allowed.class': 
                        rv = 'allowed_' + itemSet[0].allowed 
                        break
                }
                
            }
            return rv
        },       
    }
}
</script>


<style scoped lang="scss">
@import "@/assets/_var.scss";

.vcs-complexity { position: relative; width: 240px; }
.vcs-complexity:after { clear: both; }
.vcs-complexity .com-label { position: absolute; top: -7px; left: 100%; }
.vcs-complexity .com-label .cml-set { margin-left: 24px; padding: 0px; font-size: 13px; font-weight: 600; white-space: nowrap; }
.vcs-complexity.allowed_true .com-label .cml-set { color: $greenToneFont; }
.vcs-complexity.allowed_false .com-label .cml-set { color: $redToneFont; }
.vcs-complexity .com-section { float: left; width: 19%; margin-right: 1%; border: 1px solid #d7d7d7; }
.vcs-complexity .com-section .csn-item { height: 8px; }
.vcs-complexity.allowed_true .com-section .csn-item.active { background-color: $greenToneBG; }
.vcs-complexity.allowed_false .com-section .csn-item.active { background-color: $redToneBG; }

</style>
